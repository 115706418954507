var lang = sessionStorage.getItem('lysomed_lang') || getLanguage(['de', 'fr', 'it']);
if (lang && window.location.pathname !== '/' + lang + '/') {
	window.location.replace('./' + lang);
}

function getLanguage(availableLanguages) {
	var userLangs = navigator.languages
		? navigator.languages
		: [navigator.language];
	var primaryLangs = userLangs.map((lang) => lang.split('-')[0].toLowerCase());

	// Check for a direct match in the available languages
	var match = userLangs
		.concat(primaryLangs)
		.find((lang) => availableLanguages.includes(lang));
	return match || 'de'; // default to German if no match found
}
